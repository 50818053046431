/* App.css */

body {
  font-family: 'Roboto', sans-serif;
  background-color: #eaf6f6; /* Light teal background */
  color: #333; /* Dark text color */
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.header {
  background-color: #03dac6; /* Teal header background */
  padding: 20px;
  color: #fff; /* White text color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.title {
  font-size: 2.5em; /* Larger title font size */
}

.content {
  padding: 20px;
}

.game-over {
  margin-top: 50px;
}

.game-over-message {
  font-size: 2em;
  color: #333; /* Dark text color */
  margin-bottom: 20px;
}

.time-taken {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666; /* Light gray text color */
}

.new-game-button {
  padding: 12px 24px;
  background-color: #03dac6; /* Teal button color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.new-game-button:hover {
  background-color: #02b0a6; /* Darker teal on hover */
}

/* WordDisplay.css */

.word-display {
  background-color: #fff; /* White background */
  border-radius: 16px; /* Rounded corners */
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  max-width: 600px;
  width: 90%;
  margin: 20px auto;
  text-align: center;
}

.definition {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666; /* Light gray text color */
}

.masked-word {
  font-size: 2em;
  color: #333; /* Dark text color */
  margin-bottom: 20px;
}

/* Controls.css */

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.loading-text {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

.start-button,
.stop-button,
.share-button {
  padding: 12px 24px;
  margin: 10px 0;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.start-button {
  background-color:
  linear-gradient(90deg, #03dac6, #02b0a6); /* Teal button color */
}

.start-button:hover {
  background-color: linear-gradient(90deg, #02b0a6, #018a84); /* Darker teal on hover */
}

.stop-button {
  background-color: linear-gradient(90deg, #ff4081, #ff0057); /* Pink button color */
}

.stop-button:hover {
  background-color: linear-gradient(90deg, #ff0057, #d5004e); /* Darker pink on hover */
}

.share-button {
  background-color: linear-gradient(90deg, #03dac6, #02b0a6); /* Teal button color */
}

.share-button:hover {
  background-color: linear-gradient(90deg, #02b0a6, #018a84); /* Darker teal on hover */
}

button:focus {
  outline: none;
}

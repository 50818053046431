/* WordDisplay.css */

.word-display {
  background-color: #fff; /* White background */
  border-radius: 16px; /* Rounded corners */
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  max-width: 600px;
  width: 90%;
  margin: 20px auto;
  text-align: center;
}

.success-message {
  font-size: 2em;
  color: #4caf50; /* Green success color */
  margin-bottom: 20px;
}

.revealed-word,
.masked-word {
  font-size: 2em;
  color: #333; /* Dark text color */
  margin-bottom: 20px;
}

.definition {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666; /* Light gray text color */
}

.time-left {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #ff4081; /* Pink color for time left */
}

.guess-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guess-label {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333; /* Dark text color */
}

.guess-input {
  padding: 12px;
  border: 2px solid #4caf50; /* Green border */
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  color: #333; /* Dark text color */
}

.submit-button,
.new-game-button,
.share-button {
  padding: 12px 24px;
  background-color: #f26522; /* Orange button color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.submit-button:disabled,
.new-game-button:disabled,
.share-button:disabled {
  background-color: #ced4da; /* Light gray for disabled buttons */
  cursor: not-allowed;
}

.submit-button:hover,
.new-game-button:hover,
.share-button:hover {
  background-color: #d94e1a; /* Darker orange on hover */
}

.meme {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

@media (max-width: 600px) {
  .word-display {
    padding: 20px;
  }

  .success-message,
  .revealed-word,
  .masked-word,
  .definition,
  .time-left {
    font-size: 1.5em; /* Smaller font size on smaller screens */
  }

  .guess-input {
    width: 100%;
  }

  .submit-button,
  .new-game-button,
  .share-button {
    width: 100%;
  }

  .meme {
    width: 100%;
  }
}

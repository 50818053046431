/* Controls.css */

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
}

.loading-text {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

.start-button,
.stop-button,
.share-button {
  padding: 12px 24px;
  margin: 10px 0;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.start-button {
  background: linear-gradient(90deg, #56ab2f, #a8e063);
}

.start-button:hover {
  background: linear-gradient(90deg, #56ab2f, #76c774);
  transform: translateY(-2px);
}

.stop-button {
  background: linear-gradient(90deg, #e53935, #e35d5b);
}

.stop-button:hover {
  background: linear-gradient(90deg, #e53935, #e57676);
  transform: translateY(-2px);
}

.share-button {
  background: linear-gradient(90deg, #007bff, #00c6ff);
}

.share-button:hover {
  background: linear-gradient(90deg, #007bff, #3399ff);
  transform: translateY(-2px);
}

button:focus {
  outline: none;
}
